import { getIconColor } from "@lib/utils"
import clsx from "clsx"
import React from "react"

const OffplanVideoIcon = ({ className, color }) => {
  const stroke = getIconColor(color)

  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={clsx("video-icon", className)} width="50" height="21" viewBox="0 0 50 21" fill="none">
    <g clip-path="url(#clip0_8696_10668)">
      <path d="M38.6918 8.43417C40.0569 8.62031 41.4168 8.84782 42.7663 9.14772C44.1132 9.45278 45.4576 9.81473 46.7503 10.3654C47.394 10.6498 48.0326 10.9704 48.6013 11.459C48.8806 11.7072 49.1494 12.0019 49.3175 12.3923C49.4002 12.5862 49.4467 12.8059 49.4519 13.0283C49.4519 13.2506 49.4028 13.4678 49.3278 13.6694C49.1649 14.065 48.9038 14.3752 48.6272 14.6363C48.348 14.8975 48.0481 15.1095 47.7404 15.3008C47.1225 15.6834 46.4762 15.9833 45.8221 16.2444C44.5114 16.7692 43.167 17.1519 41.8123 17.4828C40.4576 17.8111 39.0926 18.0774 37.7249 18.3049C34.9845 18.7573 32.2234 19.0521 29.4571 19.2485L29.3769 17.5655C32.1044 17.4957 34.832 17.3277 37.5388 17.0071C38.8909 16.8442 40.2378 16.6451 41.5719 16.384C42.9033 16.1229 44.227 15.8075 45.4912 15.3654C46.122 15.1431 46.7425 14.8897 47.3087 14.5717C47.5905 14.414 47.8619 14.2382 48.0946 14.0365C48.3273 13.8375 48.5212 13.6126 48.6349 13.3747C48.6841 13.2558 48.7177 13.1343 48.7229 13.0128C48.7229 12.8913 48.7073 12.7672 48.6608 12.6431C48.5703 12.3949 48.3816 12.1519 48.1567 11.9347C47.6991 11.5029 47.1122 11.1643 46.5124 10.867C45.2999 10.2827 43.9917 9.86126 42.6732 9.5019C41.3521 9.14513 40.0078 8.85558 38.6582 8.60997L38.6867 8.42383L38.6918 8.43417Z" fill="#07234B"/>
      <path d="M10.7911 8.62022C9.44416 8.86841 8.10497 9.15538 6.78645 9.50957C5.47053 9.86893 4.16753 10.2878 2.95502 10.8694C2.35781 11.1642 1.76836 11.5003 1.30817 11.9294C1.08066 12.144 0.89452 12.3844 0.793693 12.6326C0.749742 12.7567 0.723889 12.8808 0.726474 13.0023C0.73423 13.1238 0.754913 13.2454 0.806619 13.3617C0.907446 13.5995 1.10393 13.8245 1.33144 14.0261C1.56153 14.2278 1.8304 14.4036 2.10962 14.5639C2.67322 14.8844 3.28852 15.1378 3.91934 15.3601C5.18097 15.8048 6.49948 16.1176 7.82575 16.3813C9.1546 16.6425 10.499 16.8441 11.8485 17.007C14.5476 17.3302 17.2673 17.5008 19.9871 17.5706L19.9069 19.2536C17.1484 19.0571 14.395 18.7598 11.6624 18.3048C10.2973 18.0773 8.93485 17.8084 7.58532 17.4801C6.23578 17.1466 4.894 16.764 3.58842 16.2366C2.93692 15.9729 2.29059 15.673 1.67787 15.2878C1.3728 15.0939 1.07291 14.8793 0.796278 14.6156C0.52482 14.3519 0.263703 14.0416 0.111169 13.6383C0.0310245 13.4341 -0.00258461 13.2092 7.02421e-07 12.9894C0.00775665 12.7671 0.0646336 12.5525 0.147364 12.3586C0.323165 11.976 0.592038 11.6838 0.871252 11.4408C1.44002 10.9599 2.07859 10.6419 2.71975 10.3576C4.00983 9.80947 5.34902 9.44753 6.69338 9.14504C8.04033 8.84515 9.39762 8.62022 10.7575 8.43408L10.7859 8.62022H10.7911Z" fill="#07234B"/>
      <path d="M16.3935 14.3491C15.8661 14.3491 15.3438 14.2328 14.8682 14.0079C14.863 14.0079 14.8578 14.0027 14.8526 14.0001C14.3588 13.77 13.9659 13.4314 13.6712 12.9841C13.3764 12.5394 13.2032 12.0068 13.1463 11.389H14.9948C15.0362 11.6604 15.1189 11.8853 15.243 12.0637C15.3697 12.2421 15.5326 12.3765 15.7342 12.467C15.9359 12.5575 16.1686 12.6041 16.4349 12.6041C16.8873 12.6041 17.2363 12.4825 17.4845 12.2369C17.7301 11.9939 17.8542 11.6475 17.8542 11.2002V10.9055C17.8542 10.422 17.7456 10.0498 17.5259 9.78348C17.3061 9.51977 16.9933 9.38792 16.5874 9.38792H15.7342V7.6661H16.5874C16.9338 7.6661 17.2027 7.55235 17.3914 7.32226C17.5801 7.09216 17.6732 6.77158 17.6732 6.36052V6.09682C17.6732 5.70902 17.5724 5.41171 17.3682 5.2023C17.1639 4.99289 16.8769 4.88947 16.5047 4.88947C16.2771 4.88947 16.0755 4.9386 15.8971 5.03167C15.7187 5.12732 15.5662 5.2721 15.4395 5.466C15.3128 5.6599 15.2223 5.89775 15.168 6.17955H13.3273C13.4074 5.54614 13.5936 5.00064 13.8831 4.54304C14.1727 4.08544 14.545 3.73901 15 3.50116C15.455 3.2659 15.9695 3.14697 16.5434 3.14697C17.5 3.14697 18.2394 3.39516 18.7668 3.89413C19.2916 4.39309 19.5553 5.09372 19.5553 5.99858V6.25452C19.5553 6.78193 19.4157 7.24211 19.1391 7.63508C18.8625 8.02805 18.4695 8.3176 17.9628 8.50375C18.5264 8.63043 18.9633 8.91481 19.2709 9.35949C19.5812 9.80416 19.7363 10.3729 19.7363 11.0606V11.3166C19.7363 11.9551 19.6122 12.5006 19.3614 12.9531C19.1132 13.4055 18.7487 13.7493 18.2704 13.9872C17.7921 14.2225 17.2182 14.3414 16.5486 14.3414H16.3909L16.3935 14.3491Z" fill="#07234B"/>
      <path d="M22.9886 13.9591C22.5207 13.7006 22.1639 13.3231 21.9157 12.8268C21.6701 12.3304 21.546 11.7332 21.546 11.0351V11.0274C21.546 10.6241 21.5951 10.2001 21.6934 9.7554C21.7916 9.31072 21.9312 8.86605 22.1122 8.42396C22.1432 8.34899 22.1743 8.27401 22.2053 8.20162C22.2389 8.12924 22.2725 8.05685 22.3061 7.98704L24.6148 3.26367H26.6081L23.8909 8.7342L23.635 8.46274C23.7616 8.22748 23.9452 8.04392 24.1856 7.91207C24.4261 7.78022 24.7079 7.71558 25.031 7.71558C25.6153 7.71558 26.1143 7.84485 26.5331 8.1008C26.9519 8.35674 27.2699 8.73161 27.4871 9.22024C27.7068 9.71145 27.8154 10.3009 27.8154 10.9912V10.9989C27.8154 11.7047 27.6913 12.3071 27.4457 12.8112C27.2001 13.3154 26.8407 13.698 26.3702 13.9591C25.8997 14.2202 25.3361 14.3521 24.6768 14.3521C24.0176 14.3521 23.454 14.2228 22.986 13.9643L22.9886 13.9591ZM25.667 12.2295C25.8971 11.9736 26.0134 11.6065 26.0134 11.1282V11.1204C26.0134 10.5879 25.8945 10.1768 25.6593 9.88725C25.424 9.59769 25.0827 9.45292 24.6406 9.45292C24.227 9.45292 23.909 9.59769 23.6867 9.88983C23.4617 10.182 23.3506 10.593 23.3506 11.1282V11.136C23.3506 11.6142 23.4669 11.9788 23.697 12.2347C23.9271 12.4881 24.2554 12.6148 24.6768 12.6148C25.0982 12.6148 25.4343 12.4855 25.6644 12.2295H25.667Z" fill="#07234B"/>
      <path d="M30.5248 13.5272C29.9793 12.9791 29.7053 12.2138 29.7053 11.234V6.24436C29.7053 5.25935 29.9793 4.49151 30.5274 3.94601C31.0755 3.40051 31.8718 3.12646 32.9111 3.12646C33.9504 3.12646 34.7544 3.39792 35.2999 3.94084C35.8454 4.48376 36.1194 5.25159 36.1194 6.24177V11.2314C36.1194 12.2164 35.8454 12.9843 35.2973 13.5298C34.7492 14.0753 33.9529 14.3493 32.9137 14.3493C31.8744 14.3493 31.0703 14.0753 30.5248 13.5272ZM33.9762 12.1932C34.2037 11.9088 34.3149 11.5003 34.3149 10.9677V6.50806C34.3149 5.97548 34.2011 5.567 33.9762 5.28262C33.7487 4.99823 33.3945 4.85604 32.9111 4.85604C32.4276 4.85604 32.0734 4.99823 31.8459 5.28262C31.6184 5.567 31.5072 5.97548 31.5072 6.50806V10.9677C31.5072 11.5003 31.621 11.9088 31.8459 12.1932C32.0708 12.4776 32.4276 12.6197 32.9111 12.6197C33.3945 12.6197 33.7487 12.4776 33.9762 12.1932Z" fill="#07234B"/>
      <path d="M37.3113 3.25595C36.5512 3.25595 35.9333 2.63806 35.9333 1.87797C35.9333 1.11789 36.5512 0.5 37.3113 0.5C38.0714 0.5 38.6892 1.11789 38.6892 1.87797C38.6892 2.63806 38.0714 3.25595 37.3113 3.25595ZM37.3113 1.24974C36.9648 1.24974 36.683 1.53154 36.683 1.87797C36.683 2.22441 36.9648 2.50621 37.3113 2.50621C37.6577 2.50621 37.9395 2.22441 37.9395 1.87797C37.9395 1.53154 37.6577 1.24974 37.3113 1.24974Z" fill="#07234B"/>
      <path d="M29.2632 16.3508L26.3573 18.4165C26.2797 18.4707 26.2849 18.5871 26.3651 18.6362L29.408 20.4821C29.4959 20.5364 29.6096 20.4692 29.607 20.3658L29.47 16.4542C29.4674 16.3508 29.3485 16.2913 29.2632 16.3508Z" fill="#07234B"/>
      <path d="M20.075 16.3583L22.6422 18.4085C22.712 18.4628 22.7068 18.5688 22.6344 18.6179L19.9302 20.4716C19.8423 20.5336 19.7208 20.4664 19.726 20.3578L19.863 16.4514C19.8656 16.3428 19.9922 16.2859 20.075 16.3532V16.3583Z" fill="#07234B"/>
    </g>
    <defs>
      <clipPath id="clip0_8696_10668">
        <rect width="49.4519" height="20" fill="white" transform="translate(0 0.5)"/>
      </clipPath>
    </defs>
  </svg>
  )
}



export default OffplanVideoIcon
