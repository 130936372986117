import React, { useState } from "react"
import clsx from "clsx"
import VideoModal from "./VideoModal"

const EmbedButton = ({ embedURL, className, children }) => {
  const [isOpen, setIsOpen] = useState(false)

  if (!embedURL) return null

  return isOpen ? (
    <VideoModal isOpen={isOpen} setIsOpen={setIsOpen} embedURL={embedURL} />
  ) : (
    <button
      className={clsx("embed-button", className)}
      onClick={() => setIsOpen(true)}
      aria-label="embed button"
    >
      {children}
    </button>
  )
}

export default EmbedButton
