import React, { useRef, useState } from "react"

// import { isEmpty } from "lodash"
import FORM_CONFIG, { SUCCESS_MSG } from "./config"
import Form from "./elements/Form"
import LanguageSelect from "./elements/LanguageSelect"
import PhoneInput from "./elements/PhoneInput"
import Recaptcha from "./elements/Recaptcha"
import TermsConditions from "./elements/TermsConditions"
import TextInput from "./elements/TextInput"
import { brochureSchema } from "./schemas"
import {
  formTracking,
  generateUuid,
  getBitrixData,
  isProduction,
  postFormData,
  sendMail,
  useBlock,
} from "./utils"
import useEmailContent from "@hooks/dataHooks/useEmailContent"
import { useGlobalStore } from "@stores/globalStore"
const BrochureForm = ({ offplan }) => {

  const recaptchaRef = useRef()
  const data = useEmailContent()
  const { ipAddress } = useGlobalStore()
  const formConfig = FORM_CONFIG.brochure
  let emailContentTemp = data?.filter((item) => item.choose_template === formConfig?.emailTempUser);
  const emailContent = emailContentTemp[0]?.content?.data?.content;
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async (data) => {
    setIsLoading(true); // Start loading
    const ipList = await useBlock();
    const isBlocked = ipList?.ip?.some(item => item?.add_ip_address === ipAddress);
    const isBlocked1 = ipList?.email?.some(item => item?.email === data.email);

    const token = await recaptchaRef.current?.executeAsync()
    sessionStorage.setItem("fromData", JSON.stringify(data))
    if (token) {
      const unique_id = generateUuid()
      sessionStorage.setItem("unique_id", unique_id)
      const postData = {
        first_name: data.name.split(" ")[0],
        propertyName: offplan?.title,
        offplanURL: window.location.href,
        url: window.location.href,
        unique_id: unique_id,
        ...data,
        ...formConfig,
        extra: { formConfig, data },
        propertyType: offplan?.bitrix?.property_type,
        utm_campaign: offplan?.bitrix?.campaign_name,
        lead_src: offplan?.bitrix?.source,
        utm_medium: offplan?.bitrix?.project_location,
        utm_term: offplan?.bitrix?.developer_name,
        isOffPlan: true,
        agent_segmentation: offplan?.agent_segmentation,
        "g-recaptcha-response": token,
      }

      const bitrixData = getBitrixData(postData)

      const formData = new FormData()

      formData.append("data", JSON.stringify(bitrixData))
      recaptchaRef.current?.reset()
      if(isBlocked || isBlocked1){
        setIsLoading(false); // End loading
        return;
      }
      else {
        try {
          await postFormData(formData);
          await sendMail(bitrixData);
          await handleBrochureDownload(offplan);
          formTracking({
            unique_id: unique_id,
            eventTracking: formConfig.eventTracking,
            formName: formConfig.form_name,
            email: data?.email,
            phone: data?.telephone
          })
        } catch (error) {
          setIsLoading(false); // End loading
          console.error("Error submitting form:", error);
        } finally {
          setIsLoading(false); // End loading
        }
       
       

        
      }
    }

  }

  const handleBrochureDownload = async (offplan) => {
    if (offplan?.brochure?.file?.url) {
      // Download the brochure
      const response = await fetch(offplan.brochure.file.url, { method: "GET" });
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `${offplan.title} Brochure`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url); // Clean up the URL object
    } else if (offplan?.brochure?.url) {
      const url = offplan.brochure.url; // Assuming offplan.brochure.url is a valid URL
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `${offplan.title} Brochure`;
      a.target = "_blank"; // Open in a new tab
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a); // Clean up the element after click
    }
  };

  return (
    <div className="brochure-form-section">
      <Form
        className="brochure-form"
        validationSchema={brochureSchema}
        onSubmit={onSubmit}
        successMsg={SUCCESS_MSG}
        formname="registerintrest"
      >
        <div className="form-section">
          <TextInput name="name" placeholder="Name" label="Name" />
          <TextInput
            name="email"
            placeholder="Email"
            type="email"
            label="Email"
          />
          <PhoneInput name="telephone" placeholder="Phone" label="Phone" />
          <LanguageSelect />
          <div className="d-none"><TextInput name="emailContent" type="hidden" value={emailContent} /></div>
        </div>
        <button className="button button-orange submit-button" type="submit">
          <span>{isLoading ? "Loading..." : "Download"}</span>
        </button>
        <Recaptcha recaptchaRef={recaptchaRef} />
        <TermsConditions />
      </Form>
    </div>
  )
}

export default BrochureForm
