import Animation from "@components/elements/Animation"
import Slider from "@components/elements/Slider/Slider"
import ChevronLeft from "@components/icons/ChevronLeft"
import ChevronRight from "@components/icons/ChevronRight"
import clsx from "clsx"
import React from "react"
import { Container } from "react-bootstrap"
import "./SliderModule.scss"
import CheckCircle from "@components/icons/CheckCircle"

const SliderModuleNew = ({
  title,
  heading,
  amenities,
  wrapperClassname,
  size,
  sectionClassname,
  length,
  showArrows,
  noSliderArrows,
  id,
}) => {
  const sliderRef = React.useRef(null)
  const [currentSlide, setCurrentSlide] = React.useState(0)

  const handleNext = () => {
    if (
      currentSlide ===
      Number(length) - Number(sliderRef.current?.props.slidesToShow)
    )
      return
    sliderRef.current?.slickGoTo(currentSlide + 1)
    setCurrentSlide(currentSlide + 1)
  }

  const handlePrev = () => {
    if (currentSlide === 0) return
    sliderRef.current?.slickGoTo(currentSlide - 1)
    setCurrentSlide(currentSlide - 1)
  }

  return (
    <Animation
      animateInType="up"
      className={clsx(
        "slider-module-wrap",
        wrapperClassname,
        sectionClassname || "section-mp"
      )}
    >
      <Container className="slider-module-container" id={id}>
        <div className="top-section">
          <div className="content-section">
            {heading && (
              <p className="heading">
                <span>{heading}</span>
              </p>
            )}
            <h2 className="title">
              <span>{title}</span>
            </h2>
          </div>
          
        </div>
        <Animation animateInType="up" className="property-features-section">
          <div className="features-wrap">
            {amenities.map((item, index) => (
              <Animation
                animateInType="up"
                className="feature-item"
                key={`${item}amenity`}
                delay={index * 50}
              >
                <CheckCircle />
                <p className="feature-text">{item?.text}</p>
              </Animation>
            ))}
          </div>
        </Animation>
      </Container>
    </Animation>
  )
}

export default SliderModuleNew
