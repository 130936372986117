import React from "react"
import { offCurrConvertPrice } from "@components/CurrencyConverter/CurrConvertPrice"
import { useGlobalStore } from "@stores/globalStore"

const OffCurrencyPrice = ({ price }) => {
  const { currencyType } = useGlobalStore()
  function convertToInternationalCurrencySystem (labelValue) {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9

    ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
    // Six Zeroes for Millions 
    : Math.abs(Number(labelValue)) >= 1.0e+6

    ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
    // Three Zeroes for Thousands
    : Math.abs(Number(labelValue)) >= 1.0e+3

    ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(0) + "K"

    : Math.abs(Number(labelValue));

}
let conPrice = offCurrConvertPrice(price)

  return <>{currencyType?.value+" "+convertToInternationalCurrencySystem(conPrice.replace(/,/g,''))}</>
}

export default OffCurrencyPrice
