import BrochureModalWithTrigger from "@components/BrochureModal/BrochureModal"
import ArrowRight from "@components/icons/ArrowRight"
import clsx from "clsx"
import FsLightbox from "fslightbox-react"
import React from "react"
import { Container } from "react-bootstrap"
import "./FloorPlans.scss"
import { isEmpty } from "lodash"

const FloorPlanItem = ({ floorPlan, onClick, selected, hasImage }) => {
  if (!floorPlan) return null

  const { title, size } = floorPlan

  return (
    <div
      className={clsx("floorplan-item-wrap", { selected })}
      onClick={onClick}
    >
      <div className="floorplan-item">
        <div className="content">
          <p className="title">{title}</p>
          <p className="size">{size}</p>
        </div>
        {hasImage && <ArrowRight />}
      </div>
    </div>
  )
}

const FloorPlans = ({ offplan }) => {
  const floorPlans = offplan?.floor_plans

  const [selectedFloorPlan, setSelectedFloorPlan] = React.useState(
    floorPlans?.[0]
  )

  const [toggler, setToggler] = React.useState(false)
  const [selectedImage, setSelectedImage] = React.useState(0)

  if (isEmpty(floorPlans)) return null

  const handleFloorPlanClick = (floorPlan, index) => {
    setSelectedFloorPlan(floorPlan)
    setSelectedImage(index)
  }

  const imageUrls = floorPlans.map((floorPlan) => floorPlan?.media?.url)

  if (!offplan.floor_plans) return null

  const hasImage = !!selectedFloorPlan?.media?.url

  return (
    <div className="floorplans-wrap section-m" id="floor-plans">
      <Container className="floorplans-container">
        <h2 className="title">Floorplans</h2>
        <div className="content-section">
          <div className="left-section">
            <div className="floorplan-section">
              {floorPlans.map((floorPlan, index) => (
                <FloorPlanItem
                  key={floorPlan.id}
                  floorPlan={floorPlan}
                  selected={selectedFloorPlan?.id === floorPlan.id}
                  onClick={() => handleFloorPlanClick(floorPlan, index)}
                  hasImage={hasImage}
                />
              ))}
            </div>
            <BrochureModalWithTrigger
              buttonText="Download Floorplans"
              className="button button-gray"
              offplan={offplan}
            />
          </div>
          {hasImage && (
            <div className="img-section img-zoom">
              <img
                src={selectedFloorPlan?.media?.url}
                alt=""
                onClick={() => {
                  setToggler(!toggler)
                }}
              />
            </div>
          )}
        </div>
      </Container>
      <FsLightbox
        toggler={toggler}
        sources={imageUrls}
        sourceIndex={selectedImage}
        type="image"
      />
    </div>
  )
}

export default FloorPlans
