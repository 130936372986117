import React from "react"
import { Container } from "react-bootstrap"
import "./PaymentPlans.scss"

const PaymentPlanItem = ({ plan }) => {
  if (!plan) return null

  const { title, description } = plan

  return (
    <div className="plan-item">
      <p className="plan-title">{title}</p>
      <p className="plan-description">{description}</p>
    </div>
  )
}

const PaymentPlans = ({ offplan }) => {
  if (!offplan.payment_plans) return null

  const { payment_plans: plans } = offplan

  return (
    <div className="payment-plans-wrap section-l-m" id="payment-plans">
      <Container className="payment-plans-container">
        <div className="left-section">
          <h2 className="title">Payment Plans</h2>
          {/* <p className="description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit vivamus nec
            felis convallis lorum ipsum.
          </p> */}
          <div className="payment-plans-section">
            {plans.map((plan) => (
              <PaymentPlanItem key={plan.id} plan={plan} />
            ))}
          </div>
        </div>
        {/* <div className="right-section">
          <h2 className="title">Need financial information?</h2>
          <FinancialInfoForm offplan={offplan} />
        </div> */}
      </Container>
    </div>
  )
}

export default PaymentPlans
