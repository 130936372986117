import BrochureModalWithTrigger from "@components/BrochureModal/BrochureModal"
import { scrollToID } from "@lib/utils"
import clsx from "clsx"
import React, { useState } from "react"
import { Container } from "react-bootstrap"
import Slider from "react-slick"
import "./OffplanNavBar.scss"
import RegisterInterestModalWithTrigger from "@components/RegisterInterestModal/RegisterInterestModal"
import { isEmpty, throttle } from "lodash"
import EmbedButton from "@components/elements/PlayVideo/EmbedButton"
import OffplanVideoIcon from "@components/icons/OffplanVideoIcon"
const OffplanNavBar = ({ offplan }) => {
  const {
    about,
    images,
    media_images,
    floor_plans,
    amenities,
    location_tile,
    payment_plans,
    brochure,
    video_module,
  } = offplan
  const [isNavTop, setIsNavTop] = React.useState(true)

  const settings = {
    swipeToSlide: true,
    variableWidth: true,
    infinite: false,
    arrows: false,
    dots: false,
  }

  const buttonData = [
    about && {
      title: "Details",
      id: "offplan-details",
    },
    (images?.length > 0) ?  {
      title: "Gallery",
      id: "offplan-gallery",
    } : (media_images?.length > 0) ? {
      title: "Gallery",
      id: "offplan-gallery",
    } : null,
    !isEmpty(floor_plans) && {
      title: "Floor Plans",
      id: "floor-plans",
    },
    !isEmpty(amenities) && {
      title: "Amenities",
      id: "offplan-amenities-slider",
    },
    !isEmpty(location_tile) && {
      title: "Location",
      id: "offplan-location",
    },
    !isEmpty(payment_plans) && {
      title: "Payment Plans",
      id: "payment-plans",
    },
    brochure?.file && {
      title: "Brochure",
      id: "offplan-brochure",
    },
    (video_module?.thumbnail || video_module?.video_url) && {
      title: "Video",
      id: "offplan-video",
    },
  ].filter(Boolean)

  const [activeButton, setActiveButton] = React.useState(buttonData[0])

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // eslint-disable-next-line prefer-destructuring
            const id = entry.target.id
            const button = buttonData.find((_button) => _button.id === id)
            setActiveButton(button)
          }
        })
      },
      { threshold: 0.5 }
    )

    buttonData.forEach((button) => {
      const target = document.getElementById(button.id)
      if (target) {
        observer.observe(target)
      }
    })

    const handleScroll = throttle(() => {
      const navBar = document.querySelector(".offplan-nav-bar-wrap")
      if (navBar) {
        const { top } = navBar.getBoundingClientRect()
        if (top > 1) {
          setIsNavTop(false)
        } else {
          setIsNavTop(true)
        }
      }
    }, 100)

    window.addEventListener("scroll", handleScroll)

    return () => {
      buttonData.forEach((button) => {
        const target = document.getElementById(button.id)
        if (target) {
          observer.unobserve(target)
        }
      })

      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <div
      className={clsx("offplan-nav-bar-wrap", {
        "offplan-nav-bar-wrap--top": isNavTop,
      })}
    >
      <Container className="offplan-nav-bar-container">
        <Slider {...settings}>
          {buttonData.map((_button) => (
            <NavItem
              key={_button.id}
              button={_button}
              activeButton={activeButton}
              setActiveButton={setActiveButton}
            />
          ))}
          <EmbedButton
            className="nav-bar-item"
            embedURL={offplan?.virtual_tour_url}
          >
            <OffplanVideoIcon /> Video Tour
          </EmbedButton>
        </Slider>
        <div className="cta-section">
          <BrochureModalWithTrigger offplan={offplan} />
          <RegisterInterestModalWithTrigger offplan={offplan} />
        </div>
      </Container>
    </div>
  )
}

const NavItem = ({ button, activeButton, setActiveButton }) => {
  const [isPointMoved, setIsPointMoved] = useState(false)

  const sliderOnSwipe = {
    onPointerDown: (e) => {
      if (e.buttons === 2) return
      setIsPointMoved(false)
    },
    onPointerMove: (e) => {
      if (e.buttons === 2) return
      setIsPointMoved(true)
    },
    onPointerUp: (e) => {
      if (isPointMoved || e.buttons === 2) return

      scrollToID(button.id, 100)
      setTimeout(() => {
        setActiveButton(button)
      }, 500)
    },
  }
  return (
    <button
      className={clsx("nav-bar-item", {
        active: activeButton.id === button.id,
      })}
      {...sliderOnSwipe}
    >
      {button.title}
    </button>
  )
}

export default OffplanNavBar
